import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useOrganisations } from "../Auth/useOrganisations";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

function Repeater({ onChange, defaultValue, ...props }) {
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const [org, setOrg] = useState();

  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
      }
    });
  }, []);

  const [data, setData] = useState(
    defaultValue
      ? defaultValue
      : [
          { key: "Modèle", value: "" },
          { key: "Motorisation", value: "" },
          { key: "Boite", value: "" },
          { key: "Année", value: "" },
          { key: "Carburant", value: "" },
          { key: "Kilomètrage", value: "" },
          { key: "Immatriculation", value: "" },
        ]
  );

  const [isATitle, setIsATitle] = useState(false);
  const [shouldAddItem, setShouldAddItem] = useState(false);

  useEffect(() => {
    if (org && org.type === 'Showroom' && shouldAddItem) {
      let tempData = data;
      tempData.push({
        key: "",
        value: "",
      });
      setData(tempData);
      onChange(tempData);
    }
    setShouldAddItem(false); // Reset the flag
  }, [org, shouldAddItem, data, onChange]);

  const forceUpdate = useForceUpdate();

  const addItem = () => {
    if (org && org.type === 'Showroom') {
      setShouldAddItem(true);
    }
  };

  const removeItem = (index) => {
    let tempData = data;
    tempData.splice(index, 1);
    setData(tempData);
    forceUpdate();
    onChange(tempData);
  };

  const updateElement = (index, key, value) => {
    let tempData = data;
    if (key === "key") {
      tempData[index].key = value;
    } else {
      tempData[index].value = value;
    }
    setData(tempData);
    forceUpdate();
    onChange(tempData);
  };

  return (
    <div className="repeater-container" style={{ margin: "0px 50px 0px 50px" }}>
      {data.map((item, index) => (
        <div className="row" key={index}>
          <div className="col-5">
            <input
              type="text"
              className="form-control"
              name={"key" + index}
              value={item.key}
              onChange={(e) =>
                updateElement(index, "key", e.currentTarget.value)
              }
            />
          </div>
          <div className="col-6">
            <textarea
              disabled={isATitle}
              type="text"
              className="form-control"
              name={"value" + index}
              value={item.value}
              onChange={(e) =>
                updateElement(index, "value", e.currentTarget.value)
              }
            ></textarea>
          </div>

          <div className="col-1">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch onChange={(e) => setIsATitle(e.target.checked)} />
                }
                label="Titre"
              />
            </FormGroup>
            <a
              className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5"
              onClick={() => removeItem(index)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        </div>
      ))}
      <div className="row">
        <a
          className="btn btn-success"
          style={{ width: "100%", margin: "15px 5px 15px 15px" }}
          onClick={() => {
            setIsATitle(false);
            addItem();
          }}
        >
          Ajouter un champ
        </a>
      </div>
    </div>
  );
}

export default Repeater;
