import React, { useEffect, useState } from "react";
import { useOrganisations } from "./../Auth/useOrganisations";
import { Link, useParams } from "react-router-dom";
import { editCart, getCarts } from "../Services/CartApi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import { getOrganisationById } from "../Services/OrganizationApi";
import { getUserById } from "../Services/UserApi";
import { useToken } from "./../Auth/useToken";
import { useUser } from "./../Auth/useUser";
import { IconButton } from "@mui/material";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};

function ReceptionOrders() {
  const { id } = useParams(); //id org from url
  const [carts, setCarts] = useState([]);
  const [cart, setCart] = useState();
  const [date, setDate] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [rand, setRand] = useState(0);
  const [currency, setCurrency] = useState();
  const [displayedCart, setDisplayedCart] = useState({
    produits: [],
    table: 0,
    prix: 0,
    status: "Nouveau",
  });

  const user = useUser();
  const [infoUser] = useState(user.result);

  const handlePrint = () => {
    // Hide the button before printing
    const printButton = document.getElementById("printButton");
    if (printButton) {
      printButton.style.display = "none";
    }

    // Print the modal
    window.print();

    // Restore the button's display after printing
    if (printButton) {
      printButton.style.display = "block";
    }
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [modalOpen, setModalOpen] = useState(false);
  const displayOrderModal = (cart) => {
    setDisplayedCart(cart);
    setModalOpen(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };
  const getOrderColor = (status) => {
    switch (status) {
      case "Annulée":
        return "primary";
        break;
      case "Nouveau":
        return "warning";
        break;
      case "Livrée":
        return "info";
        break;
      case "Payée":
        return "success";
        break;
      default:
        return "danger";
    }
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const forceUpdate = useForceUpdate();

  function newArray(cart) {
    const result = cart.produits.reduce((acc, curr) => {
      const key = `${curr.titre.fr}`;
      const prix = `${curr.prix}`;
      const existingItem = acc.find((item) => item.key === key);

      if (existingItem) {
        existingItem.data.push(curr);
      } else {
        acc.push({ key, data: [curr], prix });
      }

      return acc;
    }, []);

    console.log(result);
    setCartProducts(result);
  }

  useEffect(() => {
    console.log(infoUser);
    if (infoUser.organisation_id !== id) {
      window.location.href = "/receptionorders/" + infoUser.organisation_id;
    }
    setUsedOrganisations(id);

    getCarts(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCarts(res.data.data);
        setCarts(
          res.data.data.filter((cart) => infoUser.tables.includes(cart.table))
        );
        forceUpdate();
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
      }
    });
  }, [rand, usedOrganisations]);

  useEffect(() => {
    // Function to fetch the carts
    const fetchCarts = () => {
      getCarts(id)
        .then((res) => {
          const success = res.data.success;
          if (success === "0") {
            alert("error");
          } else {
            // Filter carts based on infoUser tables
            const filteredCarts = res.data.data.filter((cart) =>
              infoUser.tables.includes(cart.table)
            );
            setCarts(filteredCarts);
          }
        })
        .catch((error) => {
          console.error("Error fetching carts:", error);
          alert("Error fetching carts.");
        });
    };

    // Fetch carts initially
    fetchCarts();

    // Fetch carts every second
    const intervalId = setInterval(fetchCarts, 1000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [id, infoUser.tables]);

  function editOrder(status, cartId) {
    editCart(status, cartId).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setRand(rand + 1);
      }
    });
  }
  const promptForDelivered = (orderId) => {
    swal({
      title: "Est-ce que cette commande est livrée ?",
      text: "Appuyez sur oui pour changer le statut de la commande en 'livrée'",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editOrder("Livrée", orderId);
        swal("Commande livrée !", {
          icon: "success",
        });
      } else {
        swal("Commande encore en cours!");
      }
    });
  };
  const promptForPaid = (orderId) => {
    swal({
      title: "Est-ce que cette commande est payée ?",
      text: "Appuyez sur oui pour changer le statut de la commande en 'Payée'",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editOrder("Payée", orderId);
        swal("Commande Payée !", {
          icon: "success",
        });
      } else {
        swal("Commande encore en cours!");
      }
    });
  };
  return (
    <section className="content" style={{ padding: "1px 2px 0px 2px" }}>
      <div className="row">
        <div className="col-12">
          {/* <button onClick={(e)=> console.log(carts)}></button> */}
          <div className="box">
            <div className="box-body">
              <div className="table-responsive">
                <table
                  id="productorder"
                  className="table table-hover no-wrap product-order"
                  data-page-size="10"
                >
                  <thead>
                    <tr>
                      <th>Table</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {carts &&
                      carts.map((value, index) => (
                        <tr>
                          <td
                            onClick={(e) => {
                              newArray(value);
                              displayOrderModal(value);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {value.table}
                          </td>

                          <td>
                            <span
                              className={
                                "badge badge-pill badge-" +
                                getOrderColor(value.status)
                              }
                              onClick={(e) => {
                                if (value.status === "Livrée") {
                                  promptForPaid(value.id);
                                } else {
                                  promptForDelivered(value.id);
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {value.status}
                            </span>
                          </td>
                          <td>
                            <button
                              className="waves-effect waves-circle btn btn-circle btn-success-light btn-xs mb-5"
                              onClick={(e) => editOrder("En cours", value.id)}
                            >
                              <i className="fa fa-solid fa-check"></i>
                            </button>
                            <button
                              className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5"
                              onClick={(e) => editOrder("Annulée", value.id)}
                            >
                              <i className="fa fa-solid fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
        style={{ color: "white" }}
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "black" }}
          >
            Details de la commande
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2 className="d-inline">
                    <span className="font-size-30" style={{ color: "black" }}>
                      Table numéro {displayedCart.table}
                    </span>
                  </h2>
                </div>
              </div>
              {/* /.col */}
            </div>
            <div className="row invoice-info">
              {/* /.col */}

              {/* /.col */}
              {/* <div className="col-sm-12 invoice-col mb-15">
                <div className="invoice-details row no-margin">
                  <div className="col-md-6 col-lg-3">
                    <b>Commande </b> {displayedCart.id}
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <b>Payment Due:</b> {displayedCart.createdAt}
                  </div>
                </div>
              </div> */}
              {/* /.col */}
            </div>
            <div className="row">
              <div className="col-12 table-responsive">
                <table
                  className="table table-bordered"
                  style={{ color: "#b5b5c3" }}
                >
                  <tbody>
                    <tr>
                      <th>Article</th>
                      <th className="text-right">Prix</th>
                    </tr>
                    {cartProducts &&
                      cartProducts.map((val, index) => (
                        <tr>
                          <td>
                            X{val.data.length} {val.key}{" "}
                          </td>
                          <td className="text-right">
                            {" "}
                            {val.prix * val.data.length} {currency}{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* /.col */}
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <p className="lead">
                  <b style={{ color: "#b5b5c3" }}>Date</b>
                  <span className="text-danger">
                    {" "}
                    {new Date(displayedCart.createdAt).getDate()}/
                    {new Date(displayedCart.createdAt).getUTCMonth() + 1}/
                    {new Date(displayedCart.createdAt).getFullYear()}{" "}
                  </span>
                </p>

                {/* <div>
                  <p>Sub - Total amount : $3,592.00</p>
                  <p>Tax (18%) : $646.56</p>
                  <p>Shipping : $110.44</p>
                </div> */}
                <div className="total-payment">
                  <h3 style={{ color: "black" }}>
                    <b>Total :</b> {displayedCart.prix.toFixed(2)} {currency}
                  </h3>
                </div>
              </div>
              {/* /.col */}
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button
                className="waves-effect btn btn-success-light mb-5"
                id="printButton"
                onClick={handlePrint}
                style={{ display: "block" }}
              >
                <i className="fa-solid fa-print"></i> Imprimer
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </section>
  );
}
export default ReceptionOrders;
