import React, { useEffect, useState } from "react";
import "./MenusStyle/menu1.css";
import { useOrganisations } from "../Auth/useOrganisations";
import { getMenuByCarteId, getMenus } from "../Services/MenuApi";
import { Link, useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";
import { registerScan } from "../Services/TrackingApi";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
import Modal from "@mui/material/Modal";
import { QuantityPicker } from "react-qty-picker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactFlagsSelect, { Fr, Us } from "react-flags-select";

import { Close, Money } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { addComment } from "../Services/CommentsApi";
import {
  Button,
  Checkbox,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { addCart } from "../Services/CartApi";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import props from "prop-types";
import { Global } from "@emotion/react";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import { addDemande } from "../Services/DemandeApi";
import HeinekenAdPopup from "../Components/AdPopUp";
import Cart from "./MenusStyle/cart.png";
import Bottle from "./MenusStyle/bottle.png";
import fr from "./MenusStyle/france.png";
import eng from "./MenusStyle/england.png";
import ar from "./MenusStyle/tun.png";
import NoImage from "./MenusStyle/no-image.png";
import Cash from "./MenusStyle/money.png";
import Tpe from "./MenusStyle/card-machine.png";
import Coupon from "./MenusStyle/coupon.png";
import Cheque from "./MenusStyle/cheque.png";
import TipsPopUp from "../Components/TipsPopUp";
import { getPopup } from "../Services/PopupApi";
import BuyProductPopUp from "../Components/SuggestionPopUp";
import { getPersonalisationMenu } from "../Services/TemplateMenuApi";
import { getCartes } from "../Services/CartesApi";
import { changeLanguage } from "../i18n";
import { getActiveHappyHours, getHappyHours } from "../Services/HappyHourApi";
import { getSocials } from "../Services/SocialsApi";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "white",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}
function Menu() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { id, table, id_category } = useParams(); //id org from url
  const [menus, setMenus] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [currentCarte, seturrentCarte] = useState(id_category);
  const [categories, setCategories] = useState([]);
  const [org, setOrg] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cartes, setCartes] = useState([]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  function handleQuantityChange(newQuantity) {
    if (!newQuantity) {
      newQuantity = 1;
    }
    setQuantity(newQuantity);
  }
  const [comment, setComment] = useState();
  const [star, setStar] = useState(1);
  const [cartProducts, setCartProducts] = useState([]);
  const [paymentMethods, setPaymenMethods] = useState([]);
  const [displayedMenu, setDisplayedMenu] = useState();
  const [currency, setCurrency] = useState();
  const [socials, setSocials] = useState();
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [popup, setPopup] = useState();
  const [sendReviewytton, setSendReviewButton] = useState(false);
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [tips, setTips] = useState(0);
  const [checked, setChecked] = useState("Cash");
  const [suggestedProduct, setSuggestedProduct] = useState();
  const [suggestedCategory, setSuggestedCategory] = useState();
  const [suggestion, setSuggestion] = useState();
  const [personalisationMenu, setPesonalisationMenu] = useState();
  const [themeColor, setThemeColor] = useState();
  const [fontColor, setFontColor] = useState();
  const [background, setBackground] = useState();
  const [happyHours, setHappyHours] = useState([]);
  const [lang, setLang] = useState("Fr");
  const [direction, setDirection] = useState("20px");
  const [descriptionDirection, setDescriptionDirection] = useState("left");
  const [addButtonDirection, setAddButtonDirection] = useState("right");
  const [layout, setLayout] = useState("unset");

  const translation = (titre) => {
    if (lang === "Fr") {
      return titre.fr;
    } else if (lang === "En") {
      return titre.en;
    } else return titre.ar;
  };

  const arabicLayout = () => {
    setDirection("90%");
    setDescriptionDirection("right");
    setAddButtonDirection("left");
    setLayout("rtl");
  };
  const francoLayout = () => {
    setDirection("20px");
    setDescriptionDirection("left");
    setAddButtonDirection("right");
    setLayout("unset");
  };
  const handleToggle = (value) => () => {
    const newChecked = value === checked ? "" : value;
    setChecked(newChecked);
  };
  const [addition, setAddition] = useState({
    produits: [],
    prix: 0,
    tips: 0,
  });
  const [cart, setCart] = useState({
    produits: [],
    table: atob(table),
    prix: 0,
    status: "Nouveau",
  });
  const forceUpdate = useForceUpdate();

  const handleTipChange = (value) => {
    setTips(value);
  };
  const addProductToCart = (product) => {
    let tempQuantity = 1;
    const content = document.createElement("div");
    ReactDOM.render(
      <div>
        <QuantityPicker
          min={1}
          value={quantity}
          onChange={(value) => {
            handleQuantityChange();
            tempQuantity = value;
          }}
        />
      </div>,
      content
    );
    // const tempQuantity = quantity; // Store the value of quantity in a separate variable
    Swal.fire({
      title: "<span style='color: #353535'>Quantité :</span>",
      html: content,
      confirmButtonColor: "#3b3b3b",
      confirmButtonText: "Ajouter",
      onClose: () => {
        ReactDOM.unmountComponentAtNode(content);
      },
    }).then(function () {
      let tempCart = cart;
      if (tempQuantity > 0) {
        for (var i = 0; i < tempQuantity; i++) {
          let tempCart = cart;
          tempCart.produits.push(product);
          const result = tempCart.produits.reduce((acc, curr) => {
            const key = `${translation(curr.titre)}`;
            const prix = `${curr.prix}`;
            const existingItem = acc.find((item) => item.key === key);

            if (existingItem) {
              existingItem.data.push(curr);
            } else {
              acc.push({ key, data: [curr], prix });
            }

            return acc;
          }, []);

          setCartProducts(result);

          tempCart.prix += getPrice(product, happyHours);
          setCart(tempCart);
          forceUpdate();
          //promptForAdd();
        }

        forceUpdate();
      }
    });
  };

  const removeProductFromCart = (product, index) => {
    let tempCart = {
      produits: [],
      table: atob(table),
      prix: 0,
      status: "Nouveau",
    };
    let tempCartProduits = cartProducts;
    tempCartProduits[index].data.splice(0, 1);
    if (tempCartProduits[index].data.length === 0) {
      tempCartProduits.splice(index, 1);
    }
    setCartProducts(tempCartProduits);

    for (var i = 0; i < tempCartProduits.length; i++) {
      for (var j = 0; j < tempCartProduits[i].data.length; j++) {
        tempCart.produits.push(tempCartProduits[i].data[j]);
        tempCart.prix += getPrice(tempCartProduits[i].data[j], happyHours);
      }
    }
    forceUpdate();
    setCart(tempCart);
    return;
  };
  const promptForDelete = () => {
    swal({
      title: t("Nous avons bien reçu votre avis !"),
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const promptForAddition = () => {
    swal({
      title: "Attendez, notre serveur va venir à votre table!",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const promptForCart = () => {
    swal({
      title: "Commande confirmée !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
    addComment(comment, star, atob(table), id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };
  const callWaiter = async (tips) => {
    let tempAddition = addition;
    tempAddition.tips = tempAddition.tips + tips;
    setAddition(tempAddition);
    addDemande(atob(table), tips, checked, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForAddition();
        console.log(res.data.data);
      }
    });
    forceUpdate();
  };
  const CartAdd = async (tipValue) => {
    setTips(tips + tipValue);
    const arr = [];
    let tempCart = cart;
    cart.produits.forEach((value) => {
      if ("id" in value) {
        arr.push(value.id);
      } else {
        arr.push(value._id);
      }
    });
    //@ToDo: Test all input errors here.
    tempCart.produits = arr;
    tempCart.prix = tempCart.prix + tipValue;
    addCart(tempCart, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        let tempAddition = addition.produits;
        tempAddition = tempAddition.concat(cartProducts);
        const groupedArray = tempAddition.reduce((acc, item) => {
          const index = acc.findIndex((element) => element.key === item.key);
          if (index === -1) {
            acc.push({
              key: item.key,
              data: item.data.slice(),
              prix: item.prix,
            });
          } else {
            acc[index].data.push(...item.data);
          }
          return acc;
        }, []);

        console.log(groupedArray);
        setAddition({
          produits: groupedArray,
          prix: addition.prix + tempCart.prix,
          tips: 0,
        });

        setCart({
          produits: [],
          table: atob(table),
          prix: 0,
          status: "Nouveau",
        });
        setCartProducts([]);
        promptForCart();
        forceUpdate();
      }
    });
    return false;
  };
  const addSuggestionAndConfirm = () => {
    const product = suggestedProduct;
    let tempCart = cart;
    tempCart.produits.push(product);
    const result = tempCart.produits.reduce((acc, curr) => {
      const key = `${curr.titre.fr}`;
      const prix = `${curr.prix}`;
      const existingItem = acc.find((item) => item.key === key);

      if (existingItem) {
        existingItem.data.push(curr);
      } else {
        acc.push({ key, data: [curr], prix });
      }
      return acc;
    }, []);
    setCartProducts(result);
    tempCart.prix += product.prix;
    setCart(tempCart);
    forceUpdate();
  };
  const handleRating = (rate) => {
    setStar(rate);
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  let initialized = false;

  const checkDate = (start_date, end_date, startTime, endTime) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const currentDate = new Date();
    startDate.setMinutes(
      startDate.getMinutes() + startDate.getTimezoneOffset()
    );
    endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());

    const timeInRange =
      startDate.getFullYear() === startDate.getFullYear() &&
      startDate.getFullYear() === currentDate.getFullYear() &&
      startDate.getMonth() === currentDate.getMonth() &&
      startDate.getDate() === currentDate.getDate() &&
      endDate.getFullYear() === currentDate.getFullYear() &&
      endDate.getMonth() === currentDate.getMonth() &&
      endDate.getDate() === currentDate.getDate();

    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const isWithinTimeRange =
      currentTime >= startTime && currentTime <= endTime;

    return timeInRange && isWithinTimeRange;
  };

  useEffect(() => {
    let initialized = false;

    // Function to display the alert message
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "You are going to lose your data. Are you sure you want to leave?";
    };

    // Add the event listener to display the alert message
    window.addEventListener("beforeunload", handleBeforeUnload);
    setUsedOrganisations(id);
    if (!initialized) {
      registerScan(id);
      initialized = true;
    }
    getMenuByCarteId(usedOrganisations, currentCarte).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setMenus(res.data.data);
        let catList = [];
        let filtredData = res.data.data.filter(
          (arr) => arr.category_id && arr.dispo === true
        ); //we remove every product without category
        const result = filtredData.reduce((acc, curr) => {
          acc[curr.category_id._id] = acc[curr.category_id._id] || [];
          acc[curr.category_id._id].push(curr);
          return acc;
        }, {});
        let catMenu = Object.values(result);
        catMenu.sort((a, b) => a[0].category_id.ordre - b[0].category_id.ordre);
        setCategoryListing(catMenu);
        catMenu.forEach((element) => {
          catList.push(element[0].category_id);
        });
        const filteredCategories = catList.filter((cat) => cat.dispo === true);

        // Pass the filteredCategories to setCategories function
        setCategories(filteredCategories);
      }
    });
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        changeLanguage("fr");
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
        setPaymenMethods(res.data.data.paiementmode);
      }
    });
    getPopup().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setPopup(res.data.data);
        console.log(res.data.data.menu_id);
        setSuggestedProduct(res.data.data.menu_id);
        setSuggestedCategory(res.data.data.category_id);
        setSuggestion(res.data.data.suggestion);
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
      }
    });
    getCartes(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCartes(res.data.data);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    const style = document.createElement("style");
    getPersonalisationMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        if (res.data.data.length) {
          style.innerHTML = `
          :root {
            --themecolor: ${res.data.data[0].themecolor};
            --fontcolor: ${res.data.data[0].fontcolor}; 
            --font: ${res.data.data[0].font}; 
          }
        `;
          document.body.appendChild(style);
          setPesonalisationMenu(res.data.data[0]);
          setThemeColor(res.data.data[0].themecolor);
          setFontColor(res.data.data[0].fontcolor);
          setBackground(res.data.data.background);
          document.body.style.backgroundImage =
            "url(../../../images/auth-bg/backgroundmenu/bg-16.jpg)";
          document.body.style.backgroundPosition = "center";
        }
        console.log(res.data.data);
      }
      document.getElementById("btn-nothing").click();
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [currentCarte]);

  useEffect(() => {
    document.getElementById("btn-nothing").click();
  }, [categories]);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleDrawer2 = (newOpen) => () => {
    setOpen2(newOpen);
  };

  // This is used only for the example
  const container = window !== undefined ? () => document.body : undefined;

  function getPrice(val2, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === val2.id
    );

    if (happyHourItem) {
      return val2.prix - happyHourItem.reduction;
    } else {
      return val2.prix;
    }
  }

  return (
    <section className="menu1">
      {popup && popup.adimage ? <HeinekenAdPopup /> : <></>}
      <button
        onClick={(e) => {
          window.dispatchEvent(new Event("dataLoad"));
        }}
        id="btn-nothing"
        style={{ display: "none" }}
      >
        test
      </button>{" "}
      <input type="hidden" value={currentCarte} id="carte_id" />
      {/*this button to solve a shitty bug that's all */}
      <div className="wrapper">
        <div className="row">
          <div className="col-6" style={{ textAlign: "left" }}>
            <button
              onClick={goBack}
              className="btn btn-rounded"
              style={{ backgroundColor: "white", display: "flex" }}
            >
              <i
                className="fa-solid fa-chevron-left"
                style={{ fontSize: "30px" }}
              ></i>
            </button>
          </div>
          <div className="col-6" style={{ textAlign: "right" }}>
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{ backgroundColor: "white", display: "flex" }}
              >
                <div className="btn-text">
                  {lang === "Fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "En" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "Ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white" }}
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("Fr");
                    changeLanguage("fr");
                    francoLayout();
                  }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("En");
                    changeLanguage("en");
                    francoLayout();
                  }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("Ar");
                    changeLanguage("ar");
                    arabicLayout();
                  }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div style={{ direction: layout }}>
          <div className="rox">
            <h2> {org && org.nom} </h2>
          </div>

          <div className="banner">
            {org && org.logo ? (
              <img src={process.env.REACT_APP_API_BASE_URL + org.logo} />
            ) : (
              ""
            )}
          </div>
          {org && org.message ? <p>{org.message}</p> : null}
          <div className="buttons-container">
            {cartes.map((val, index) => {
              return (
                <Link
                  exact
                  key={index}
                  className={
                    currentCarte === val.id
                      ? "button2 button--is-active"
                      : "button2"
                  }
                  onClick={(e) => seturrentCarte(val.id)}
                >
                  {translation(val.libelle)}
                </Link>
              );
            })}
          </div>

          <div className="buttons-container">
            {categories.map((val, index) => {
              return (
                <Link
                  exact
                  key={index}
                  className="button"
                  data-target={"menu" + index}
                >
                  {translation(val.libelle)}
                </Link>
              );
            })}
          </div>
          {categories.map((val, index) => {
            return (
              <div className="menu" id={"menu" + index}>
                {categoryListing[index].map((val2, index2) => {
                  return (
                    <div
                      className={
                        val2.titre.fr === "Heineken" ||
                        val2.titre.fr === "Coca-Cola"
                          ? "item .menu--is-visible heineken"
                          : "item .menu--is-visible"
                      }
                    >
                      <div
                        className="item__header"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        {personalisationMenu &&
                        personalisationMenu.productimage &&
                        val2.image.length ? (
                          <div
                            className="avatar avatar-lg "
                            style={{ marginRight: "5px" }}
                          >
                            <img
                              className="rounded img-fluid"
                              src={
                                val2.image.length
                                  ? process.env.REACT_APP_API_BASE_URL +
                                    val2.image
                                  : ""
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <h3
                          onClick={(e) => {
                            setDisplayedMenu(val2);
                            handleOpen();
                          }}
                          className={
                            val2.titre.fr === "Heineken" ||
                            val2.titre.fr === "Coca-Cola"
                              ? "item__title heineken"
                              : "item__title"
                          }
                          style={{ textAlign: "left" }}
                        >
                          {translation(val2.titre).length > 15
                            ? translation(val2.titre).substring(0, 15) + " ..."
                            : translation(val2.titre)}
                        </h3>

                        <span
                          className="item__price"
                          style={{ right: direction, position: "absolute" }}
                        >
                          {getPrice(val2, happyHours) !== val2.prix ? (
                            <h1
                              className="pro-price"
                              style={{
                                fontSize: "31px",
                                color: themeColor,
                                marginTop: "0px",
                              }}
                            >
                              {getPrice(val2, happyHours)}
                              <span
                                className="old-price"
                                style={{ fontSize: "24px" }}
                              >
                                {val2.prix}
                              </span>
                            </h1>
                          ) : (
                            val2.prix
                          )}

                          {/* {happyHours.find(
                          (item) => item.menu_id._id === val2.id
                        ) ? (
                          <h1
                            className="pro-price"
                            style={{ fontSize: "31px", color: themeColor }}
                          >
                            {val2.prix -
                              happyHours.find(
                                (item) => item.menu_id._id === val2.id
                              ).reduction}
                            <span
                              className="old-price"
                              style={{ fontSize: "24px" }}
                            >
                              {val2.prix}
                            </span>
                          </h1>
                        ) : (
                          val2.prix
                        )} */}
                        </span>
                      </div>

                      <p
                        className="item__description"
                        style={{ float: descriptionDirection }}
                        onClick={(e) => {
                          setDisplayedMenu(val2);
                          handleOpen();
                        }}
                      >
                        {val2 && translation(val2.description) !== "null" ? (
                          <>
                            {" "}
                            {translation(val2.description).length > 30
                              ? translation(val2.description).substring(0, 30) +
                                " ..."
                              : translation(val2.description)}{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </p>

                      {personalisationMenu && personalisationMenu.options ? (
                        <button
                          type="button"
                          className="waves-effect waves-circle btn btn-circle btn-xs mb-5"
                          onClick={(e) => addProductToCart(val2)}
                          style={{
                            float: addButtonDirection,
                            backgroundColor: themeColor,
                            color: fontColor,
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div style={{ overflow: "hidden" }}>
            <h4
              style={{
                fontSize: "15px",
                whiteSpace: "nowrap",
                animation: "scroll 10s linear infinite",
              }}
            >
              {currency === "TND"
                ? t("Tous les prix sont en Dinar Tunisien.")
                : currency === "CFA"
                ? t("Tous les prix sont en CFA.")
                : currency === "Dinar libyen"
                ? t("Tous les prix sont en Dinar libyen")
                : currency === "$"
                ? t("Tous les prix sont en Dollar $")
                : t("Tous les prix sont en Euro €")}
            </h4>
          </div>

          <footer className="footer mt-0">
            <div className="bon-appetit">
              <h2>{t("bon appetit")}</h2>
            </div>
            {personalisationMenu && personalisationMenu.options ? (
              <div className="row" style={{ justifyContent: "center" }}>
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
                  onClick={(e) => {
                    addition.prix > 0
                      ? setOpen2(true)
                      : toast.error(t("Passez une commande d'abord !"));
                  }}
                >
                  <span className="btn-addition-text">{t("Addition")}</span>
                </button>
              </div>
            ) : (
              <></>
            )}
            <form onSubmit={onSubmit}>
              <div className="mx-auto mt-20">
                <h3 className="text-uppercase text-white">{t("Votre avis")}</h3>
                <div>
                  <Rating
                    onClick={handleRating}
                    initialValue={1}
                    ratingValue={star}
                    size={20}
                    label
                    transition
                    fillColor="orange"
                    emptyColor="gray"
                    className="foo" // Will remove the inline style if applied
                    value={star}
                    onChange={(e) => setStar(e.currentTarget.value)}
                  />
                  {/* Use rating value */}
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control p-10"
                    placeholder={t("Pensez-vous......")}
                    value={comment}
                    onChange={(e) => setComment(e.currentTarget.value)}
                    style={{ backgroundColor: "white" }}
                  />
                  {/* {comment === !null ? setSendReviewButton(true): setSendReviewButton(false)} */}

                  <div className="input-group-append">
                    <button type="submit" className="btn btn-success">
                      {t("envoyer")}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div
              className="social-media mt-10"
              style={{ justifySelf: "center" }}
            >
              {/* {JSON.stringify(socials)} */}
              <nav>
                <Link
                  onClick={(e) => (window.location.href = socials.facebook)}
                  className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                >
                  <img src={Facebook} />
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.instagram)}
                  className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                >
                  <img src={Instagram} />{" "}
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.youtube)}
                  className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                >
                  <img src={Youtube} />{" "}
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.website)}
                  className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                >
                  <img src={Website} />{" "}
                </Link>
              </nav>
            </div>
            <div
              className="paiement-mode"
              style={{
                borderRadius: "10px",
                padding: "5px 5px 5px 10px",
                justifyContent: "center",
              }}
            >
              {/* <h6 className="row" style={{ justifyContent: "center" }}>
              Les methodes de paiement disponibles :{" "}
            </h6>{" "} */}
              <div className="row" style={{ justifyContent: "center" }}>
                {paymentMethods &&
                  paymentMethods.map((value) => (
                    <div className="col-3" style={{ justifyContent: "center" }}>
                      <p>
                        <div
                          className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            width: "50px",
                            height: "50px",
                            fontSize: "24px",
                            display: "grid",
                            alignItems: "center",
                          }}
                        >
                          {value === "Cash" ? (
                            <i className="fa-regular fa-money-bill-1"></i>
                          ) : value === "Tickets Restau" ? (
                            <i className="fa-solid fa-ticket"></i>
                          ) : value === "Credit Card" ? (
                            <i className="fa-brands fa-cc-mastercard"></i>
                          ) : (
                            <i className="fa-solid fa-money-check-dollar"></i>
                          )}

                          {/* <img
                          className="rounded img-fluid"
                          src={
                            value === "Cash"
                              ? Cash
                              : value === "Tickets Restau"
                              ? Coupon
                              : value === "Credit Card"
                              ? Tpe
                              : Cheque
                          }
                        /> */}
                        </div>
                      </p>
                    </div>
                  ))}
              </div>
              {/* {paymentmodes &&
              paymentmodes
                .filter((item) => !paymentMethods.includes(item))
                .map((value) => (
                  <p className="">
                    <i
                      className={
                        value === "Cash"
                          ? "fa fa-solid fa-money-bill"
                          : value === "Tickets Restau"
                          ? "fa-solid fa-ticket"
                          : value === "Credit Card"
                          ? "fa-solid fa-credit-card"
                          : "fa-solid fa-money-check-dollar"
                      }
                    ></i>{" "}
                    {value}{" "}
                    <i
                      className="fa-solid fa-xmark"
                      style={{ color: "#bd0000" }}
                    ></i>
                  </p>
                ))} */}
            </div>
            <div className="copyright">
              <p>© 2023 SCANINY. {t("Tous droits réservés.")}</p>
            </div>
          </footer>
        </div>
      </div>
      {personalisationMenu && personalisationMenu.options ? (
        <>
          <div
            className="arrow bounce"
            style={{
              backgroundImage: `url(${Cart})`,
            }}
          ></div>
          <SwipeableDrawer
            container={container}
            anchor="left"
            open={open2}
            onClose={toggleDrawer2(false)}
            onOpen={toggleDrawer2(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            // onScroll={(event) => {
            //   console.log(event.target.scrollTop);
            // }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "8px",
                overflowY: "auto",
              }}
            >
              <IconButton
                onClick={toggleDrawer2(false)}
                sx={{ float: "right" }}
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            </div>
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            ></StyledBox>
            <StyledBox
              sx={{
                bottom: 0,
                height: "100%",
                margin: "0px 40px",
                overflowY: "auto",
              }}
            >
              <div className="box-body" style={{ padding: "0px" }}>
                <div
                  className="entete-addition"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  {/* <div
                    className="avatar avatar-lg "
                    style={{ marginRight: "5px" }}
                  >
                    <img
                      className="rounded img-fluid"
                      src={
                        org.logo
                          ? process.env.REACT_APP_API_BASE_URL + org.logo
                          : NoImage
                      }
                    />
                  </div> */}
                  <h4 style={{ color: "#5e5e5e" }}>{org && org.nom}</h4>
                </div>
                <div
                  className="table-responsive-xl"
                  style={{ overflowX: "hidden" }}
                >
                  <table className="table product-overview">
                    <thead>
                      <tr>
                        <th>{t("Designation")}</th>

                        <th>{t("prix")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addition.prix > 0 ? (
                        addition.produits &&
                        addition.produits.map((value, index) => (
                          <tr>
                            <td>
                              {value.data.length}{" "}
                              {value.key.length > 10
                                ? value.key.substring(0, 10) + " ..."
                                : value.key}
                            </td>

                            <td>
                              {" "}
                              {getPrice(value.data[0], happyHours) *
                                value.data.length}
                              {currency}{" "}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">Vous n'avez aucune commande.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12 text-right">
                      <p className="lead">
                        <b style={{ color: "#b5b5c3" }}>{t("Date")}</b>
                        <span className="text-danger">
                          {" "}
                          {new Date().toLocaleString("en-US", {
                            timeZone: "Africa/Tunis",
                          })}
                        </span>
                      </p>

                      {/* <div>
                        <p>Sub - Total amount : $3,592.00</p>
                        <p>Tax (18%) : $646.56</p>
                        <p>Shipping : $110.44</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-right">
                      <div>
                        <p>
                          {t("Prix Total")} :{" "}
                          {addition.prix.toFixed(chiffreApresVirg)} {currency}{" "}
                        </p>
                        <p>
                          {t("Tips")} :{" "}
                          {addition.tips.toFixed(chiffreApresVirg)} {currency}{" "}
                        </p>
                      </div>
                      <div className="total-payment">
                        <h3 style={{ color: "black" }}>
                          <b>Total :</b>{" "}
                          {(addition.tips + addition.prix).toFixed(
                            chiffreApresVirg
                          )}{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ margin: "0px 10px" }}>
                <List sx={{ width: "100%", maxWidth: 360 }}>
                  <h6 style={{ color: "black" }}>
                    {t("Choisir une methode de paiement")}
                  </h6>
                  {paymentMethods.map((value) => {
                    const labelId = `checkbox-list-label-${value}`;
                    return (
                      <ListItem key={value} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(value)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={value === checked}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div className="row" style={{ justifyContent: "center" }}>
                <TipsPopUp confirm={callWaiter} onChange={handleTipChange} />
              </div>
              <div
                className="row"
                style={{ justifyContent: "center", marginTop: "40px" }}
              >
                {t("Bon appétit et à bientôt !")}
              </div>
            </StyledBox>
          </SwipeableDrawer>
          <div>
            <SwipeableDrawer
              container={container}
              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              swipeAreaWidth={drawerBleeding}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: {
                  height: "calc(50%)",
                  bottom: 0,
                },
              }}
            >
              <StyledBox
                sx={{
                  position: "absolute",
                  top: -drawerBleeding,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  visibility: "visible",
                  right: 0,
                  left: 0,
                }}
              ></StyledBox>
              <StyledBox
                sx={{
                  bottom: 0,
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <div
                  className="box"
                  style={{
                    float: "none",
                    clear: "both",
                    marginBottom: "0",
                    borderRadius: "0",
                    backgroundColor: "white",
                    height: "100%",
                    boxShadow: "none",
                  }}
                >
                  <div className="box-body p-10">
                    <div
                      className="table-responsive-xl"
                      style={{ height: "100%" }}
                    >
                      <table className="table product-overview">
                        <thead>
                          <table
                            className="table product-overview"
                            style={{
                              background: "white",
                              borderBottom: "1px solid gray",
                              left: "0",
                            }}
                          >
                            <thead>
                              <tr sty>
                                <th>{t("Designation")}</th>

                                <th>{t("prix")}</th>
                              </tr>
                            </thead>
                          </table>
                        </thead>
                        <tbody>
                          {/* <tr style={{ opacity: 0 }}>
                            <th>Designation</th>

                            <th>{t("prix")}</th>
                            <th style={{ textAlign: "center" }}></th>
                          </tr> */}
                          {cart.prix > 0 ? (
                            cartProducts &&
                            cartProducts.map((value, index) => (
                              <tr>
                                <td>
                                  {value.data.length} {"  "}
                                  {value.key.length > 40
                                    ? value.key.substring(0, 40) + " ..."
                                    : value.key}
                                </td>

                                <td>
                                  {" "}
                                  {getPrice(value.data[0], happyHours) *
                                    value.data.length}{" "}
                                </td>

                                <td align="center">
                                  <button
                                    className="btn btn-circle btn-primary btn-xs"
                                    title=""
                                    onClick={(e) =>
                                      removeProductFromCart(value, index)
                                    }
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">
                                {t(
                                  "Vous n'avez aucun article dans votre panier."
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div
                        className="table-footer"
                        style={{
                          bottom: "0px",
                          position: "absolute",
                          width: "95%",
                          position: "unset",
                        }}
                      >
                        {cart && cart.prix > 0 ? (
                          <>
                            <div className="row m-0">
                              <div className="col-12 text-right">
                                <div className="total-payment">
                                  <h3 style={{ color: "black" }}>
                                    <b>{t("Total")} :</b>{" "}
                                    {cart.prix.toFixed(chiffreApresVirg)}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{ display: "contents" }}
                            >
                              <BuyProductPopUp
                                confirm={(e) => addSuggestionAndConfirm()}
                                decline={(e) => CartAdd(0)}
                                product={suggestedProduct}
                                category={suggestedCategory}
                                suggestionMessage={suggestion}
                                cartProducts={cartProducts}
                                addProductToCart={addProductToCart}
                                toggleDrawer={toggleDrawer}
                              />
                            </div>
                          </>
                        ) : null}
                        {/* <button onClick={(e)=> console.log(cartProducts)}>click</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </StyledBox>
            </SwipeableDrawer>
          </div>
        </>
      ) : null}
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "black" }}
          >
            {t("Details du Menu")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="box box-body b-1 text-center no-shadow">
                  <img
                    src={
                      displayedMenu && displayedMenu.image
                        ? process.env.REACT_APP_API_BASE_URL +
                          displayedMenu.image
                        : "../images/products/no-image.png"
                    }
                    id="product-image"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="clear"></div>
              </div>
              <div className="col-md-8 col-sm-6">
                <h2 className="box-title mt-0" style={{ color: "black" }}>
                  {displayedMenu && translation(displayedMenu.titre)}
                </h2>

                {/* <h1 className="pro-price mb-0 mt-20">
                  {displayedMenu && displayedMenu.prix} {currency}
                </h1> */}
                {displayedMenu &&
                translation(displayedMenu.description) !== "null" ? (
                  <p>
                    {displayedMenu && translation(displayedMenu.description)}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </section>
  );
}
export default Menu;

