import React, { useEffect, useState } from "react";
import { editMenu, getMenuById } from "../Services/MenuApi";
// import { useToken } from "../Auth/useToken";
import { Link, useParams } from "react-router-dom";
import { getCategories } from "../Services/CategrieApi";
import swal from "sweetalert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Repeater from "./../Components/Repeater";
import { useUser } from "../Auth/useUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { useOrganisations } from "../Auth/useOrganisations";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useTranslation } from "react-i18next";

function EditMenu() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [currentMenu, setCurrentMenu] = useState(null);
  const defaultTitre = {
    fr:
      currentMenu && typeof currentMenu.titre === "object"
        ? currentMenu.titre.fr
        : "",
    en:
      currentMenu && typeof currentMenu.titre === "object"
        ? currentMenu.titre.en
        : "",
    ar:
      currentMenu && typeof currentMenu.titre === "object"
        ? currentMenu.titre.ar
        : "",
  };
  const [titre, setTitre] = useState(defaultTitre);

  const [org, setOrg] = useState();
  const defaultDescription = {
    fr:
      currentMenu && typeof currentMenu.description === "object"
        ? currentMenu.description.fr
        : "",
    en:
      currentMenu && typeof currentMenu.description === "object"
        ? currentMenu.description.en
        : "",
    ar:
      currentMenu && typeof currentMenu.description === "object"
        ? currentMenu.description.ar
        : "",
  };
  const [description, setDescription] = useState(defaultDescription);
  const [ordre, setOrdre] = useState(1);
  const [image, setImage] = useState([]);
  const [categorie, setCategorie] = useState();
  const [prix, setPrix] = useState();
  const [dispo, setDispo] = useState(true);
  const [info, setInfo] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [loading, setLoading] = useState(true);
  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre article a été mise à jour",
      icon: "success",
    }).then(function () {
      window.location = "/menus";
    });
  };

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!titre || !selectedCategorie || !prix) {
      toast.error("S'il-vous-plaît remplissez tous les champs requis!");
      return false;
    }

    // Validate price
    const regex = /^\d+(\.\d{1,2})?$/; // Regular expression to match a valid number with up to 2 decimal places
    const isPriceValid = regex.test(prix);

    if (!isPriceValid) {
      toast.error("Price must be a valid number.");
      return false;
    }
    editMenu(titre, description, info, image, prix, dispo, ordre, id.id).then(
      (res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          // console.log(res.data.data) c
          promptForDelete();
        }
      }
    );
    return false;
  };
  useEffect(() => {
    getMenuById(id.id).then((res) => {
      const success = res.data.success;
      if (success === false) {
        //error
        alert("error");
      } else {
        setCurrentMenu(res.data.data);
        console.log(res.data.data);
        setTitre(res.data.data.titre);
        setOrdre(res.data.data.ordre ? res.data.data.ordre : 1);
        setDescription(res.data.data.description);
        setInfo(res.data.data.info);
        // setUrl(res.data.data.url); can\t find url on response
        // setImage(res.data.data.image);
        setSelectedCategorie(res.data.data.category_id._id);
        setPrix(res.data.data.prix);
        setDispo(res.data.data.dispo);
      }
    });
    getCategories().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCategorie(res.data.data);
        setLoading(false);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
      }
    });
  }, [id]);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Titre")}
                          </label>
                          <input
                            value={typeof titre === "object" ? titre.fr : titre}
                            onChange={(e) =>
                              setTitre({ ...titre, fr: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (fr)"
                          />
                          <input
                            value={typeof titre === "object" ? titre.en : titre}
                            onChange={(e) =>
                              setTitre({ ...titre, en: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (en)"
                          />
                          <input
                            value={typeof titre === "object" ? titre.ar : titre}
                            onChange={(e) =>
                              setTitre({ ...titre, ar: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (ar)"
                          />
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Titre")}
                          </label>
                          <input
                            value={titre.fr}
                            onChange={(e) =>
                              setTitre({
                                ...titre,
                                fr: e.target.value,
                                en: e.target.value,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (fr)"
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Prix")}
                        </label>
                        <input
                          defaultValue={currentMenu ? currentMenu.prix : ""}
                          onChange={(e) => setPrix(e.currentTarget.value)}
                          type="text"
                          className="form-control"
                          placeholder="Titre"
                        />
                      </div>
                      {org && org.type === "Restaurant" ? (
                        <>
                          <div className="form-group">
                            <label className="font-weight-700 font-size-16">
                              {t("Ordre dans menu")}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={ordre}
                              onChange={(e) => setOrdre(e.currentTarget.value)}
                            />
                          </div>
                          <div className="col-xl-3 col-6 text-center mb-20">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={dispo}
                                    onChange={(e) => setDispo(e.target.checked)}
                                  />
                                }
                                label="Disponibilité"
                              />
                            </FormGroup>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t("Image")}</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            image.length
                              ? URL.createObjectURL(image[0])
                              : currentMenu &&
                                currentMenu.image &&
                                currentMenu.image.length
                              ? process.env.REACT_APP_API_BASE_URL +
                                currentMenu.image[0]
                              : "../images/products/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>{t("Changer l'image")}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            type="file"
                            name="image"
                            className="upload"
                            multiple
                            onChange={(e) => setImage(e.currentTarget.files)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Description")}
                          </label>
                          <textarea
                            value={description.fr}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                fr: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (fr)"
                          ></textarea>
                          <textarea
                            value={description.en}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                en: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (en)"
                          ></textarea>
                          <textarea
                            value={description.ar}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (ar)"
                          ></textarea>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Description")}
                          </label>
                          <textarea
                            value={description.fr}
                            onChange={(e) =>
                              setDescription({
                                ...titre,
                                fr: e.target.value,
                                en: e.target.value,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* {org && org.type === "Restaurant" ? (
                    <div classNameName="row">
                      <Link
                        to={"/ingredients/" + id.id}
                        className="btn btn-success mt-10 d-block text-center p5"
                      >
                        <span>Ajouter/ Modifier Ingredients</span>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )} */}
                  {org && (org.type === "Showroom" || org.type === "Events") ? (
                    <>
                      {currentMenu ? (
                        <Repeater onChange={setInfo} defaultValue={info} />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="form-actions mt-10">
                  <Link to="/menus">
                    <button type="button" className="btn btn-danger">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary left-button"
                    onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EditMenu;
