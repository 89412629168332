import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";

function MenuRedirect() {
  const { id, table } = useParams();
  useEffect(() => {
    console.log(id, table);
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        console.log("error");
      } else {
        window.location.href = '/menustarter/'+id+'/'+table;
        // window.location.href = '/menu'+res.data.data.menu+'/'+id+'/'+table;
      }
    });
  }, []);

  return <p>Loading Menu ...</p>;
}

export default MenuRedirect;
