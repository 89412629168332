import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addPopups(adimage, category_id, menu_id, suggestion) {
  const frmData = new FormData();
  if (adimage) frmData.append("adimage", adimage);
  if (category_id) frmData.append("category_id", category_id);
  if (menu_id) frmData.append("menu_id", menu_id);
  frmData.append("suggestion", suggestion);
  frmData.append("organisation_id", currentOrg);
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };
  try {
    const response = await axios.post(api_url + "popups", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editPopup(adimage, category_id, menu_id, suggestion, id) {
  const frmData = new FormData();
  if (adimage) frmData.append("adimage", adimage);
  if (category_id) frmData.append("category_id", category_id);
  if (menu_id) frmData.append("menu_id", menu_id);
  if (suggestion) frmData.append("suggestion", suggestion);
  frmData.append("id", id);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(
      api_url + "popups",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getPopup() {
  try {
    const response = await axios.get(
      api_url + "popups/organisation/" + currentOrg
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function deleteImage(id) {
  try {
    const response = await axios.delete(api_url + "popups/image/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
