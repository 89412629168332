import React, { useEffect, useState } from "react";
import "./MenusStyle/menustarter.css";
import { Link, useParams } from "react-router-dom";
import { useOrganisations } from "../Auth/useOrganisations";
import { getEvents, getOrganisationById } from "../Services/OrganizationApi";
import { getSocials } from "../Services/SocialsApi";
import { getPersonalisationMenu } from "../Services/TemplateMenuApi";
import { getCartes } from "../Services/CartesApi";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";
import { getMenus } from "../Services/MenuApi";
import Events from "../Components/Events";

function MenuStarter() {
  const { id, table } = useParams(); //id org from url
  const [org, setOrg] = useState();
  const [socials, setSocials] = useState();
  const [background, setBackground] = useState();
  const [cartes, setCartes] = useState([]);
  const [event, setEvent] = useState([]);
  const [eventsOrg, setEventsOrg] = useState([]);
  const [menu, setMenu] = useState();
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  useEffect(() => {
    setUsedOrganisations(id);
    localStorage.setItem("is_dark", "true");
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setMenu(res.data.data.menu);
      }
    });
    getEvents(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setEventsOrg(res.data);
        console.log(res.data);
        getMenus(res.data.relatedOrganizations.id).then((res) => {
          const success = res.data.success;
          if (success === "0") {
            alert("error");
          } else {
            setEvent(res.data.data);
          }
        });
      }
    });

    getPersonalisationMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        if (res.data.data.length) {
          document.body.style.backgroundImage = `url(../../images/auth-bg/backgroundmenu/bg-${res.data.data[0].background}.jpg)`;
          document.body.style.backgroundRepeat = "no-repeat";
          document.body.style.backgroundSize = "cover";
          document.body.style.minHeight = "100vh";
        }
      }
    });

    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    getCartes(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCartes(res.data.data);
      }
    });
  }, []);

  return (
    <section
      className="menustarter"
      style={{
        display: "grid",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "80px",
      }}
    >
      <h1>{org && org.nom}</h1>
      <div className="banner" style={{ margin: "70px" }}>
        {org && org.logo ? (
          <img src={process.env.REACT_APP_API_BASE_URL + org.logo} />
        ) : (
          ""
        )}
      </div>
      <div className="buttons" style={{ display: "grid" }}>
        {cartes &&
          cartes.map((value, index) => (
            <>
              <button
                className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
                onClick={(e) =>
                  (window.location.href =
                    "/menu" + menu + "/" + id + "/" + table + "/" + value.id)
                }
              >
                {" "}
                {value.libelle.fr}{" "}
              </button>
            </>
          ))}
      </div>
    
      

      <div className="social-media mt-10" style={{ justifySelf: "center" }}>
        {/* {JSON.stringify(socials)} */}
        <nav>
          <Link
            onClick={(e) => (window.location.href = socials.facebook)}
            className="waves-effect waves-circle btn btn-circle btn-xs m-5"
          >
            <img src={Facebook} />
          </Link>
          <Link
            onClick={(e) => (window.location.href = socials.instagram)}
            className="waves-effect waves-circle btn btn-circle btn-xs m-5"
          >
            <img src={Instagram} />{" "}
          </Link>
          <Link
            onClick={(e) => (window.location.href = socials.youtube)}
            className="waves-effect waves-circle btn btn-circle btn-xs m-5"
          >
            <img src={Youtube} />{" "}
          </Link>
          <Link
            onClick={(e) => (window.location.href = socials.website)}
            className="waves-effect waves-circle btn btn-circle btn-xs m-5"
          >
            <img src={Website} />{" "}
          </Link>
        </nav>
      </div>
      <Events eventsOrg={eventsOrg} />
      <div className="copyright" style={{ justifySelf: "center" }}>
        <p style={{ backgroundColor: "#000000ad" }}>
          © 2023 SCANINY. Tous droits réservés.
        </p>
      </div>
    </section>
  );
}
export default MenuStarter;

