import React, { useEffect, useState } from "react";

import { getScanList } from "../Services/TrackingApi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useOrganisations } from "./../Auth/useOrganisations";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getComments } from "../Services/CommentsApi";
import { Rating } from "react-simple-star-rating";

import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getCarts } from "../Services/CartApi";
import { useUser } from "./../Auth/useUser";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { getMenuById } from "../Services/MenuApi";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#01b075" : "#01b075",
  },
}));

function Main() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [hourlyScans, setHourlyScans] = useState(0);
  const [allClicks, setAllClicks] = useState([]);
  const [currentChart, setCurrentChart] = useState([]);
  const [dates, setDates] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState([]);
  const [five, setFive] = useState(0);
  const [four, setFour] = useState(0);
  const [three, setThree] = useState(0);
  const [two, setTwo] = useState(0);
  const [one, setOne] = useState(0);
  const [countOrders, setCountOrders] = useState(0);
  const [countCanceledOrders, setcountCanceledOrders] = useState(0);
  const [income, setIncome] = useState(0);
  const [rating, setRating] = useState(0);
  const [topFiveProducts, setTopFiveProducts] = useState([]);
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(true);
  const [reviewedProducts, setReviewedProducts] = useState([]);
  const [org, setOrg] = useState();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const user = useUser();
  const [infoUser] = useState(user.result);

  //get the most purchaseddd product
  const maxUsedProducts = (orders, n) => {
    const products = orders.flatMap((order) => order.produits); //we will merge all orders products in one array.
    const productCounts = products.reduce((counts, product) => {
      counts[product._id] = (counts[product._id] || 0) + 1;
      return counts;
    }, {}); //we will count products and put them on array each id will face how many occurence it have
    const sortedProductCounts = Object.entries(productCounts).sort(
      (a, b) => b[1] - a[1]
    ); // we will order the productCount array DES
    // return sortedProductCounts.slice(0, n); //we will get the first n most bought products

    //here el traitement taamlou sortedProductCounts[0][0] howa awel wa7ed sortedProductCounts[1][0] et c etc
    if (sortedProductCounts.length > 0) {
      let arr = [];
      for (let i = 0; i <= 4; i++) {
        const mostSoldProduct = sortedProductCounts[i][0]; //el id
        let produit = products.find((obj) => obj._id === mostSoldProduct);
        arr.push(produit);
      }
      setTopFiveProducts(arr);

      // setBestProduct(produit); //where u will display that ?  duplicate
      // setBestCategoryId(produit.category_id);
    }
  };

  useEffect(() => {
    getScanList().then((res) => {
      const scanList = res.data.data;
      const date = new Date();
      const hour = date.getHours();
      const day =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      const dt = [...new Set(scanList.map((obj) => obj.date))];
      setAllClicks(scanList);
      setDates(dt);

      let filtredData = scanList.filter(
        (arr) => arr.date === day && arr.hour === hour
      );
      let displayedElements = scanList.filter((arr) => arr.date === day);
      setCurrentChart(displayedElements);

      if (filtredData.length) {
        setHourlyScans(filtredData[0].visits);
      }
    });
    getComments().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setReviews(res.data.data);
        setReviewsCount(res.data.data.length);
        let fiveArray = res.data.data.filter((arr) => arr.star === 5);
        let fourArray = res.data.data.filter((arr) => arr.star === 4);
        let threeArray = res.data.data.filter((arr) => arr.star === 3);
        let twoArray = res.data.data.filter((arr) => arr.star === 2);
        let oneArray = res.data.data.filter((arr) => arr.star === 1);
        let max =
          (fiveArray.length * 5 +
            fourArray.length * 4 +
            threeArray.length * 3 +
            twoArray.length * 2 +
            oneArray.length) /
          res.data.data.length;
        setRating(max.toFixed(1));
        if (res.data.data.length > 0) {
          setFive((fiveArray.length * 100) / res.data.data.length);
          setFour((fourArray.length * 100) / res.data.data.length);
          setThree((threeArray.length * 100) / res.data.data.length);
          setTwo((twoArray.length * 100) / res.data.data.length);
          setOne((oneArray.length * 100) / res.data.data.length);
        }
      }
    });

    getCarts().then((res) => {
      const orders = res.data.data;
      if (res.data.success) {
        //how many orders we have
        const filteredOrders = orders.filter((val) => val.status !== "Annulée");
        const filteredCanceledOrders = orders.filter(
          (val) => val.status === "Annulée"
        );
        setCountOrders(filteredOrders.length);
        setcountCanceledOrders(filteredCanceledOrders.length);

        //the sum of the orders price
        const totalPrice = orders.reduce(
          (sum, order) =>
            order.status !== "Annulée" ? sum + order.prix : sum + 0,
          0
        );
        setIncome(totalPrice);
        maxUsedProducts(orders, 1);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
        setLoading(false);
        setOrg(res.data.data);
      }
    });
  }, [usedOrganisations]);

  const changeChart = (value) => {
    let displayedElements = allClicks.filter((arr) => arr.date === value);
    setCurrentChart(displayedElements);
  };
  return (
    <div className="row">
      {/* <GifLoader loading={loading} /> */}
      {/* categories total */}
      {org && org.type === "Restaurant" ? (
        <>
          <div className="col-xxxl-4 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex align-items-start">
                  <div>
                    <img
                      src="../images/food/online-order-1.png"
                      className="w-80 mr-20"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 className="my-0 font-weight-700">{countOrders}</h2>
                    <p className="text-fade mb-0">{t("Commandes effectués")}</p>
                    <p className="font-size-12 mb-0 text-success">
                      <span className="badge badge-pill badge-success-light mr-5">
                        {/* <i className="fa fa-arrow-up" /> */}
                      </span>
                      {/* 3% (15 Days) */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxxl-4 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex align-items-start">
                  <div>
                    <img
                      src="../images/food/online-order-3.png"
                      className="w-80 mr-20"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 className="my-0 font-weight-700">
                      {countCanceledOrders
                        ? (
                            (countCanceledOrders * 100) /
                            (countOrders + countCanceledOrders)
                          ).toFixed(2)
                        : 0}{" "}
                      %{" "}
                    </h2>
                    <p className="text-fade mb-0">{t("Commandes Annulées")} </p>
                    <p className="font-size-12 mb-0 text-primary">
                      {(countCanceledOrders * 100) /
                        (countOrders + countCanceledOrders) >
                      50 ? (
                        <span className="badge badge-pill badge-primary-light mr-5">
                          <i className="fa fa-arrow-down"></i>
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-success-light mr-5">
                          <i className="fa fa-arrow-up"></i>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Articles total */}
          <div className="col-xxxl-4 col-lg-6 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex align-items-start">
                  <div>
                    <img
                      src="../images/food/online-order-4.png"
                      className="w-80 mr-20"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 className="my-0 font-weight-700">
                      {income.toFixed(2)} {currency}
                    </h2>
                    <p className="text-fade mb-0">{t("Revenue")}</p>
                    <p className="font-size-12 mb-0 text-success">
                      <span className="badge badge-pill badge-success-light mr-5">
                        {/* <i className="fa fa-arrow-up" /> */}
                      </span>
                      {/* 8% (15 Days) */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* Last hour scans */}

      <div className="col-xxxl-4 col-lg-6 col-12">
        <div className="box">
          <div className="box-body">
            <div className="d-flex align-items-start">
              <div>
                <img
                  src="../images/food/online-order-5.png"
                  className="w-80 mr-20"
                  alt=""
                />
              </div>
              <div>
                <h2 className="my-0 font-weight-700">{hourlyScans}</h2>
                <p className="text-fade mb-0">{t("Derniere heure scans")}</p>
                <p className="font-size-12 mb-0 text-primary">
                  <span className="badge badge-pill badge-primary-light mr-5"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* chart */}
      <div className="col-xxxl-12 col-xl-12 col-lg-12 col-12">
        <div className="box">
          <div className="box-header no-border">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="box-title">{t("Scan Chart (Aujourd'hui)")}</h4>
              {/* <h4 className="box-title">
                <select
                  className="form-control"
                  onChange={(e) => changeChart(e.target.value)}
                >
                  {dates &&
                    dates.map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                </select>
              </h4> */}
            </div>
          </div>
          <div className="box-body pt-0">
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                width={730}
                height={250}
                data={currentChart}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hour" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line dataKey="visits" fill="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* avis clients */}

      {org && org.type === "Restaurant" ? (
        <div className="col-12 col-xl-4">
          <div className="box">
            <div className="box-header with-border">
              <h4 className="box-title">{t("Top 5 produits")}</h4>
            </div>
            <div className="box-body">
              <div className="media-list media-list-divided">
                {topFiveProducts &&
                  topFiveProducts.map((value, index) => (
                    <div className="media media-single px-0">
                      <div className="avatar avatar-lg " href="#">
                        <img
                          className="rounded img-fluid"
                          src={
                            value.image
                              ? process.env.REACT_APP_API_BASE_URL + value.image
                              : "../images/product/no-image.png"
                          }
                        />
                      </div>
                      <span className="title font-weight-500 font-size-16">
                        {value.titre.fr}
                      </span>
                      {/* <div
                      className="bg-warning rounded-circle"
                      style={{
                        textAlign: "center",
                        padding: "5px",
                        width: "30px",
                      }}
                    >
                      {index + 1}
                    </div> */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="col-12 col-xl-8">
        <div className="row">
          <div className="box">
            <div className="box-body">
              <h4 className="box-title">{t("Avis clients")}</h4>
              <div className="d-md-flex d-block justify-content-between">
                <div
                  className="total-rating"
                  style={{
                    marginTop: "5px",
                    fontSize: "24px",
                    padding: "30px 30px 0 0",
                    textAlign: "center",
                  }}
                >
                  <p>{isNaN(rating) ? 0 : rating}</p>
                  <Rating
                    initialValue={rating}
                    allowFraction
                    readonly
                    size={20}
                    label
                    transition
                    fillColor="orange"
                    emptyColor="gray"
                    className="foo" // Will remove the inline style if applied
                  />
                  <p>{reviewsCount}</p>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <br />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 20 }}>
                      <Typography variant="body2">5</Typography>
                    </Box>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={five}
                      />{" "}
                    </Box>
                  </Box>
                  <br />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 20 }}>
                      <Typography variant="body2">4</Typography>
                    </Box>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={four}
                      />{" "}
                    </Box>
                  </Box>
                  <br />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 20 }}>
                      <Typography variant="body2">3</Typography>
                    </Box>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={three}
                      />{" "}
                    </Box>
                  </Box>
                  <br />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 20 }}>
                      <Typography variant="body2">2</Typography>
                    </Box>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress variant="determinate" value={two} />{" "}
                    </Box>
                  </Box>
                  <br />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 20 }}>
                      <Typography variant="body2">1</Typography>
                    </Box>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress variant="determinate" value={one} />{" "}
                    </Box>
                  </Box>
                </Box>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="box bg-transparent no-shadow">
          <div className="box-header pt-0 px-0">
            <h4 className="box-title">{t("Avis des clients")}</h4>
          </div>
          <div className="box-body px-0">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              itemclassName="carousel-item-padding-40-px"
              arrows={false}
            >
              {reviews &&
                reviews.map((values, index) => (
                  <div className="box p-0">
                    <div className="box-body">
                      <div className="d-flex align-items-center">
                        <div className="review-tx">
                          {org && org.type === "Vente par produit" ? (
                            <div className="d-flex mb-10">
                              <img
                                src={
                                  values.product_id.image.length
                                    ? process.env.REACT_APP_API_BASE_URL +
                                      values.product_id.image[0]
                                    : "../images/products/no-image.png"
                                }
                                className="w-40 h-40 mr-10 rounded100"
                                alt=""
                              />
                              <div>
                                <p className="mb-0">
                                  {values.product_id.titre.fr}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex mb-10">
                              <img
                                src="../images/avatar/3.jpg"
                                className="w-40 h-40 mr-10 rounded100"
                                alt=""
                              />
                              <div>
                                <p className="mb-0">
                                  {" "}
                                  {org && org.type === "Rating"
                                    ? t("Tél -")
                                    : t("Table")}{" "}
                                  {values.table}
                                </p>
                              </div>
                            </div>
                          )}
                          <p className="mb-10">
                            {values.comment != null ? values.comment : <>_</>}{" "}
                          </p>
                          {org && org.type === "Rating" ? (
                            <div className="d-flex text-warning align-items-center">
                              {values.star === 1 ? "😠" : null}
                              {values.star === 2 ? "😞" : null}
                              {values.star === 3 ? "😐" : null}
                              {values.star === 4 ? "😄" : null}
                              {values.star === 5 ? "🤩" : null}
                              <span className="text-fade ml-10">
                                {values.star === 1 ? "En colère" : null}
                                {values.star === 2 ? "Déçu" : null}
                                {values.star === 3 ? "Inexpressif" : null}
                                {values.star === 4 ? "Satisfait" : null}
                                {values.star === 5 ? "Ravi" : null}
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex text-warning align-items-center">
                              <Rating
                                initialValue={values.star}
                                readonly
                                size={20}
                                label
                                transition
                                fillColor="orange"
                                emptyColor="gray"
                                className="foo" // Will remove the inline style if applied
                              />
                              <span className="text-fade ml-10">
                                {values.star}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
