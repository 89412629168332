import React, { useEffect, useState } from "react";
import {
  addIngredient,
  deleteIngredient,
  editIngredient,
  getIngredientsByMenu,
} from "../Services/IngredientsApi";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";

function Ingredients() {
  const [ingredient, setingredient] = useState(null);
  const [image, setImage] = useState(null);
  const [titre, setTitre] = useState();
  const [description, setDescription] = useState();
  const [quantite, setQuantite] = useState();
  const [unite, setUnite] = useState();
  const [calories, setCalories] = useState();
  const { id } = useParams();
  const [rand, setRand] = useState(0);
  const [ingredientToEdit, setIngredientToEdit] = useState("");
  const promptForDelete = (ingredient_id) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteIngredient(ingredient_id).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre ingredient a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression du menu !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre menu est en sécurité !");
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    //@ToDo: Test all input errors here.
    if (ingredientToEdit) {
      editIngredient(
        titre,
        description,
        image,
        id,
        quantite,
        unite,
        calories,
        ingredientToEdit
      ).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          document.getElementById("dismissModal").click();
          setTitre("");
          setDescription("");
          setImage(null);
          setQuantite("");
          setCalories("");
          setUnite("");
          setRand(rand + 1);
          setIngredientToEdit("");
        }
      });
      return;
    } else {
      addIngredient(
        titre,
        description,
        image,
        id,
        quantite,
        unite,
        calories
      ).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          document.getElementById("dismissModal").click();
          setTitre("");
          setDescription("");
          setImage(null);
          setQuantite("");
          setCalories("");
          setUnite("");
          setRand(rand + 1);
        }
      });
      return;
    }

    return false;
  };

  const openEditModal = (value) => {
    setIngredientToEdit(value.id);
    setTitre(value.titre);
    setDescription(value.description);
    setQuantite(value.quantite);
    setUnite(value.unite);
    setCalories(value.calories);
    document.getElementById("openModal").click();
  };
  useEffect(() => {
    getIngredientsByMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setingredient(res.data.data);
      }
    });
  }, [rand]);

  return (
    <section className="content">
      <div className="row">
      <h2>Ingredients de menu</h2>
        <div className="col-lg-12 col-md-8">
          <div className="box">
            <div className="media-list media-list-divided media-list-hover">
              {ingredient &&
                ingredient.map((value, index) => (
                  <div className="media align-items-center">
                    <div className="avatar avatar-lg " href="#">
                      <img
                        className="rounded img-fluid"
                        src={
                          value.image
                            ? process.env.REACT_APP_API_BASE_URL + value.image
                            : "../images/product/no-image.png"
                        }
                      />
                    </div>

                    <div className="media-body">
                      <p>
                        <strong>{value.titre}</strong>

                        <small className="sidetitle">{value.quantite}</small>
                      </p>
                      <p>{value.calories}</p>
                    </div>

                    <div className="media-right gap-items">
                      <Link
                        className="media-action lead"
                        data-toggle="tooltip"
                        title="Orders"
                        onClick={(e) => promptForDelete(value.id)}
                      >
                        <i className="ti-trash"></i>
                      </Link>
                      <Link
                        className="media-action lead"
                        data-toggle="tooltip"
                        title="Receipts"
                        onClick={(e) => openEditModal(value)}
                      >
                        <i className="ti-pencil"></i>
                      </Link>
                    </div>
                  </div>
              ))}
              <div className="box-body">
              <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#myModal"
                className="btn btn-success mt-10 d-block text-center"
                id="openModal"
                onMouseEnter={(e) => {
                  setTitre("");
                  setDescription("");
                  setImage(null);
                  setQuantite("");
                  setCalories("");
                  setUnite("");
                  setIngredientToEdit("");
                }}
              >
                +
              </a>
            </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-4">
          <div className="box no-shadow">
            <div className="box-body">
              <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#myModal"
                className="btn btn-success mt-10 d-block text-center"
                id="openModal"
                onMouseEnter={(e) => {
                  setTitre("");
                  setDescription("");
                  setImage(null);
                  setQuantite("");
                  setCalories("");
                  setUnite("");
                  setIngredientToEdit("");
                }}
              >
                +
              </a>
            </div>
          </div>
        </div> */}
      </div>
      {/* modal */}
      <div
        id="myModal"
        className="modal fade in"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Ajouter un ingredient
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Titre
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Titre"
                          value={titre}
                          onChange={(e) => setTitre(e.currentTarget.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Description
                        </label>
                        <textarea
                          className="form-control p-20"
                          rows="4"
                          value={description}
                          onChange={(e) =>
                            setDescription(e.currentTarget.value)
                          }
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Quantité
                        </label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="ti-cut"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="50"
                            value={quantite}
                            onChange={(e) => setQuantite(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Unité
                        </label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="ti-money"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="cl"
                            value={unite}
                            onChange={(e) => setUnite(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Calories
                        </label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="ti-cut"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="50"
                            value={calories}
                            onChange={(e) => setCalories(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="box-title mt-20">Uploaded Image</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            image
                              ? URL.createObjectURL(image)
                              : "../images/product/no-image.png"
                          }
                          className="mb-15"
                        />
                        <p>Upload Anonther Image</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setImage(e.currentTarget.files[0])}
                            type="file"
                            name="logo"
                            className="upload"
                          />
                        </div>
                        {image ? (
                          <div className="pic-actions">
                            <button className="btn btn-success left-button">
                              Edit
                            </button>
                            <button className="btn btn-danger">Delete</button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-success">
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-default float-right"
                    data-dismiss="modal"
                    id="dismissModal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Ingredients;
