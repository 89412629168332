import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

const currentOrg = getSession("usedorganisations");

export async function addIngredient(
  titre,
  description,
  image,
  menu_id,
  quantite,
  unite,
  calories
) {
  const frmData = new FormData();
  frmData.append("titre", titre);
  frmData.append("description", description);
  frmData.append("image", image);
  frmData.append("menu_id", menu_id);
  frmData.append("quantite", quantite);
  frmData.append("unite", unite);
  frmData.append("calories", calories);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.post(
      api_url + "ingredients",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editIngredient(
  titre,
  description,
  image,
  menu_id,
  quantite,
  unite,
  calories,
  id
) {
  const frmData = new FormData();
  frmData.append("titre", titre);
  frmData.append("description", description);
  frmData.append("image", image);
  frmData.append("menu_id", menu_id);
  frmData.append("quantite", quantite);
  frmData.append("unite", unite);
  frmData.append("calories", calories);
  frmData.append("id", id);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(api_url + "ingredients", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getIngredientsByMenu(menu_id) {
  try {
    const response = await axios.get(
      api_url + "ingredients/menu/" + menu_id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getIngredientById(id) {
  try {
    const response = await axios.get(api_url + "ingredients/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteIngredient(id) {
  try {
    const response = await axios.delete(api_url + "ingredients/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
